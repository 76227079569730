import React from 'react';
import Layout from '../../components/layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ContentCopy from '../../components/content-copy';
// import ContentImageXL from '../../components/content-image-xl';
// import ContentImageL from '../../components/content-image-l';
import ContentImageM from '../../components/content-image-m';


const VisionplusDesignWorkflow = ({ data }) => (
    <Layout>
        <section className="columns is-centered case-studies is-gapless is-multiline">
            <div className="column is-12">
                <Img
                    className="featured-image"
                    fluid={data.designWorkflowHero.childImageSharp.fluid}
                    alt="Featured Image"
                />
            </div>
            <div className="column is-12 padding-bottom-0 padding-top-0">
                <div className="columns is-centered">
                    <div className="column is-5 is-offset-1">
                        <div className="page-title-wrap margin-bot-3">
                            <span className="title-decoratiion"></span>
                            <h1 className="general-page-title">Vision+ <br />Define Design Workflow that Nurtures User-centered Design</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-5 padding-top-0">
                <div className="table-wrapper">
                    <table className="table is-fullwidth">
                        <tbody>
                            <tr>
                                <td><h2>Client</h2></td>
                                <td><p>Vision+</p></td>
                            </tr>
                            <tr>
                                <td><h2>Overview</h2></td>
                                <td>
                                    <p>Vision+ is an application service for watching TV and streaming movies from Smartphones up to Smart TV. Part of MNC Group.</p><br />
                                    <p>The size of the product team is quite small at that moment, there are only 3 product owners and 3 designers. Regardless of the size of the team, we have to work on more than 4 products consist of mobile app (Android & iOS), web, and smart tv app. The struggle of switching context is real.</p><br />
                                    <p>In the early days, the team was using Adobe XD & Zeplin, there is no version control guideline, scattered design files, and even worse what gets implemented by developers was almost by ignoring the mockup from Zeplin since the design handoff quality was horrible.</p><br />
                                    <p>As a leader, I have to boost my fellow junior designer's skill to keep up with the industry standard while chasing the deadline. For the sake of the team's mental health and overall product quality, I decided to go extra proposing a new workflow that brings the product owner, designer, and developer on the same page in order to improve the workflow, design handoff quality, and accuracy of UI implementation.</p>
                                </td>
                            </tr>
                            <tr>
                                <td><h2>Outcome</h2></td>
                                <td><p>Educate dev team about the proper product design process, Migrate from XD to Figma, Developed initial UI Kit,  Migrated from Trello to Jira, defined tailored Jira board for product design, created weekly design performance report, eliminated unnecessary meetings.</p>
                                </td>
                            </tr>
                            <tr>
                                <td><h2>My Role</h2></td>
                                <td><p>UI/UX Lead</p></td>
                            </tr>
                            <tr>
                                <td><h2>Timeline</h2></td>
                                <td><p>It took roughly 2 weeks</p></td>
                            </tr>
                            <tr>
                                <td><h2>Tools</h2></td>
                                <td><p>Figma, Jira, Miro</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="columns is-centered padding-top-0 is-multiline">
                <div className="column is-12">
                    {/* <ContentImageM>
                        <Img
                            fluid={data.retroCover.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM> */}

                    <ContentCopy>
                        <h3>Understand the Real Problems</h3>
                        <p>Get the buy-in first, that's what was my main goal. try to solve big issues without support from cross-division would be almost impossible. To promote collaborative works I decided to invite the team (product owner and designers) to do a workshop that solves a couple of daily issues such as what was hot at the moment.</p>
                        <p>In order to uncover problems and bring everyone on the same page I conducted Lightning Decision Jam (LDJ), basically it's a group exercise or workshop that replace all open discussion or brainstorming with a structured process that leads to more ideas, clearer decisions and better outcomes for more information about this workshop you can visit <a target="_blank" rel="noreferrer" href="https://medium.muz.li/a-super-simple-exercise-for-solving-almost-any-product-design-challenge-f9e6c0019d7d">this article</a>.</p><br />
                        <p>Idieally, it should be done using bunch of sharpies, stick notes, voting dots, and a proper room. However, since we were under WFH situation, we did it remotely using Miro. As a side note, while I was doing this exercise I was a newcomer who had only been joining for a few weeks.</p><br />
                        <p>The result was quite satisfying, it gets their attention and solved dragged their daily issues in no time without being intimidated by the corporate organization hierarchy with so much less debate.</p><br />

                        <p>Here are some screenshots of the LDJ activities.</p>
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.ldj2.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.ldj3.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.ldj4.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.ldj5.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.ldj1.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <p>After successfully getting the trust, I continued to refine the workflow by doing some research. The remaining issues from previous LDJ also get enriched with more findings from my observation. Here is the updated list.</p>

                        <h4>Old-school mindset</h4>
                        <p>Neglect validation process in the early stage of product development.</p>
                        <h4>Information silo</h4>
                        <p>Each department isolates its knowledge. </p>
                        <h4>Rigid vertical culture and complicated bureaucracy</h4>
                        <p>It’s a common thing that happens in an old-school corporate especially the one who under a group holding, a lot of unnecessary procedures.</p>
                        <h4>Chasing unrealistic target</h4>
                        <p>Unclear requirement docs and generic roadmap caused sprints to always end up with a lot of carry-over tasks.</p>
                        <h4>Tools and devices limitation</h4>
                        <p>The team itself is quite young and the procurement process in the company could take months.</p>
                        <h4>Slow crafting process</h4>
                        <p>Previously, everything is so manual and inefficient, there are no such things as reusable component/symbol, UI kit, whatsoever. </p>
                        <h4>Versioning and collaboration is a joke</h4>
                        <p>There is no versioning system and handoff guideline, scattered assets, even the designer itself struggling to find his works.</p>
                        <h4>Untracked performance</h4>
                        <p>There is no such clear performance indicator for designers, the ones who performed well didn't get appreciation or not get noticed, the ones who underperform didn't know how to measure their performance.</p>
                    </ContentCopy>
                    <ContentCopy>
                        <h3>Finding the solutions</h3>
                        <p>The following are the efforts that I have made to overcome the problems listed above.</p>
                    </ContentCopy>
                    <ContentCopy>
                        <h2>Product Management</h2>
                        <h3>Set the optimal board in Jira</h3>
                        <p>The main goal is to encourage validation as early as possible while keeping PO, Designer, and Developer in sync. Previously, the team was use Trello which of course far from the best option for our needs.</p><br />
                        <p>Furthermore, before committing to use Jira I did compare other tools as well such as ClickUp, Notion, and Favro, etc. Narrowing down the options, the best two are ClickUp and Jira. Well, choosing Jira might quite obvious as it’s been a gold standard for a long time, but ClickUp offer so much more in terms of feature comprehensiveness and claim they fix a lot of issue/flaw that Jira has. So we give it a try for a month with the free plan. </p><br />
                        <p>Our setup was like this.</p>
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.clickup.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <p>After using it for a while, my team experienced some big turnoffs that make us reconsider committing to a premium plan. One that really hurts the experience was the nested space dropdowns and sluggish modal opening process.</p><br />
                        <p>Moving on to Jira, as someone who familiar with Jira and Atlassian environment in general it feels like home for me. </p><br />
                        <p>Previously, we use To do, In progress, Completed, In review, Canceled, and Closed. Evaluated the process and did some research about the topic helps me made a new flow (board setup).</p><br />
                        <p>It maybe looks too much at first or too rigid. But as I follow some case study it’s quite promising so I give it a try and of course, I did modify some to meet my team’s needs. It looks like this.</p>
                    </ContentCopy>

                    <ContentImageM>
                        <Img
                            fluid={data.jiraScreenshot.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>

                    <ContentCopy>
                        <ol>
                            <li>To Do</li>
                            <li>Define Scope</li>
                            <li>Wireframe in Progress</li>
                            <li>Design Check</li>
                            <li>Copy (UX Writing)</li>
                            <li>User Testing (important but skippable)</li>
                            <li>Dev/PM Review (technical feasibility)</li>
                            <li>Mockup</li>
                            <li>Spec (Detail design spec, if needed)</li>
                            <li>Done</li>
                        </ol><br />
                        <p>This flow would indirectly force designers to work with a user-centered mindset and always validate first. Apart from that, it eliminates changes by developer after design handoff (when designer's sprint is finished) since everything was validated in advance.</p>
                    </ContentCopy>

                    <ContentCopy>
                        <h4>Outcomes</h4>
                        <ol>
                            <li>My fellow junior designers are more eager to test out their design and learn the right research tools.</li>
                            <li>Showing stakeholders that process and understand better why it takes a long time to design things.</li>
                            <li>Eliminated toxic new designs by developer without coordination (they think their design was better without knowing the context)</li>
                            <li>Educate dev team that design decisions were come from validation processes, showed them why precision and little things on every pixel matter and impactful.</li>
                        </ol>

                        <h2>Weekly Sprint Report</h2>
                        <h3>Track Team and Individual Performance</h3>
                        <p>What I did here might sound like more into Product Owner works but my PO’s were struggling with their own works since there were 2 Product people that just resigned. Waiting for the hiring process wasn’t an option, it would take months so I have to do something about it before things got worse. </p>
                        <br />
                        <p>In order to track the designer’s performance, I made a weekly report in more visual in Figma, setting up some kind of open competition that motivates individuals to perform better. Furthermore, the report will be discussed in the sprint retrospective session and will be used as a reference in sprint planning as well.</p>
                    </ContentCopy>
                    <ContentImageM>
                        <Img
                            fluid={data.retroCover.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.individualPerformance.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>
                    <ContentImageM>
                        <Img
                            fluid={data.mobileAppReport.childImageSharp.fluid}
                            alt="notes"
                        />
                    </ContentImageM>
                    <ContentCopy>
                        <p>Note: Each platform has its own recap like the mobile app above.</p>
                    </ContentCopy>
                    <ContentCopy>
                        <h2>Tools</h2>
                        <h3>Choosing the Right Design Tools</h3>
                        <p>Choosing the right tools for our needs wasn’t that easy. Although it was obvious Figma multiplayer is the best bet for the current <b>WFH</b> situation we still need to consider already bought Sketch license from the previous order. Not to mention, I have to consider the learning curve for my team that came from <b>Adobe XD</b>. Moreover, I’m still new to Figma and more comfortable with Sketch environment.</p><br />
                        <p>Eventually, I gave Figma a try to find out the learning curve for a couple of days. Obviously to test out how I do things that I usually do in <b>Sketch</b> and <b>Abstract</b> to build a design system. Eventually, after trying to work on a small project and evaluating all technical aspects I decided to go with <b>Figma</b>.</p>

                        <h4>Initial Project Structure in Figma</h4>
                        <ol>
                            <li>Design System (consist of design tokens and UI Kits for all platform)</li>
                            <li>Design Docs (all design documentation works goes here)</li>
                            <li>Mobile App (consist of Android & iOS projects</li>
                            <li>Web</li>
                        </ol>
                    </ContentCopy>
                    <ContentCopy>
                        <h2>Evaluation</h2>
                        <h3>Solved or getting worse?</h3>
                        <p>The next thing the team and I should do is evaluate the implementation and adapt in a quarterly manner but it's also possible to adapt quickly in between of sprints if needed. It takes time to measure all those aspects and so many opportunities to learn something new along the way.</p>
                    </ContentCopy>
                </div>
            </div>

            <div className="column is-12">
                <hr />
            </div>

            {/* More Cases Section  */}
            <div className="column is-10">
                <div className="columns is-centered is-multiline">
                    <div className="column is-12">
                        <h3 className="has-text-centered margin-top-0">More Case Studies</h3>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/volantis-design-system">
                                        <Img fluid={data.greduDS.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/volantis-design-system"><h2 className="read-more-title">Gredu Design System Ver 0.1</h2></Link>
                                        <Link to="/case-studies/volantis-design-system"><p className="read-more-excerp">A design system starter that will cover more than 5 products in the school ecosystem (web, iOS, & Android).</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/hris-markplus">
                                        <Img fluid={data.hrisMarkplus.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/hris-markplus"><h2 className="read-more-title">HRIS MarkPlus, Inc.</h2></Link>
                                        <Link to="/case-studies/hris-markplus"><p>Human resource information system that covers attendance, leave, benefits, employees information, etc.</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4 is-flex">
                        <div className="box">
                            <div className="columns is-centered">
                                <div className="column is-12">
                                    <Link to="/case-studies/student-app-course">
                                        <Img fluid={data.visionPlusUT.childImageSharp.fluid} alt="featured image" />
                                    </Link>
                                    <div className="post-card">
                                        <Link to="/case-studies/student-app-course"><h2 className="read-more-title">Vision+ Mobile App<br />Remote UT During Pandemic</h2></Link>
                                        <Link to="/case-studies/student-app-course"><p>Uncovering design inconsistencies and usability issues in order to get the buy-in from stakeholders to improve the app.</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </Layout >
);

export default VisionplusDesignWorkflow;


export const query = graphql`
  query VisionplusDesignWorkflowQuery {
    designWorkflowHero: file(relativePath: {eq: "design-workflow-hero.png" }) {
                childImageSharp {
                fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    designWorkflowCover: file(relativePath: {eq: "design-workflow-cover.png" }) {
                childImageSharp {
                fluid(quality: 90){
                ...GatsbyImageSharpFluid
            }
        }
    }
    greduDS:file(relativePath: {eq: "gredu-ds-page.png" }) {
                childImageSharp {
                fluid {
                ...GatsbyImageSharpFluid
            }
        }
      }

    hrisMarkplus: file(relativePath: {eq: "hris-markplus.png" }) {
                childImageSharp {
                fluid(quality: 80) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    visionPlusUT: file(relativePath: {eq: "visionplus-ut-cover.png" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    ldj1: file(relativePath: {eq: "ldj-1.jpg" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    ldj2: file(relativePath: {eq: "ldj-2.jpg" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    ldj3: file(relativePath: {eq: "ldj-3.jpg" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    ldj4: file(relativePath: {eq: "ldj-4.jpg" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    ldj5: file(relativePath: {eq: "ldj-5.jpg" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    retroCover: file(relativePath: {eq: "cover-retrospective.png" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    individualPerformance: file(relativePath: {eq: "individual-performance.png" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    mobileAppReport: file(relativePath: {eq: "mobile-app-report-sample.png" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    jiraScreenshot: file(relativePath: {eq: "jira-screenshot.png" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    clickup: file(relativePath: {eq: "clickup.png" }) {
                childImageSharp {
                fluid(quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }

  }
`
